*,
html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
}

body {
  position: relative;
  font-family: Helvetica, Arial, sans-serif;
  background-color: #000;
  color: #fff;
  height: 100vh;
  font-size: 16px;
}

body:before{
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("Images/bg-image.webp");
  background-position: right center;
  background-size: auto 100%;
  background-repeat: no-repeat;
}

@media (min-width: 768px){
  body:before{
    width: 40%;
  }
}

#logo{
  display: block;
  width: auto;
  height: 56px;
  position: absolute;
  margin: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
}

#logo img{
  display: block;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
}

@media (min-width: 768px){
  #logo{
    left: 0;
    transform: unset;
    background: rgba(0,0,0,0.9);
    padding: 0.5rem;
    height: calc(56px + 1rem);
  }
}

nav {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(15px);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

@media (min-width: 768px){
  nav {
    width: 300px;
    margin: 1.5rem;
    top: 0;
    right: 0;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    height: fit-content;
  }
}

nav ul {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  align-items: flex-end;
  list-style: none;
}

nav ul li {
  padding: 0.5rem 1rem 0.75rem 1rem;
  text-align: center;
  color: #fff;
  width: 50%;
}

nav ul li a {
  display: block;
  position: relative;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.6rem;
}

nav ul li a.active-link:after {
  content: "";
  position: absolute;
  bottom: calc(100% + 12px);
  left: 50%;
  transform: translateX(-8px);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgba(255, 255, 255, 0.3);
}

@media (min-width: 768px){
  nav ul li a.active-link:after {
    bottom: -12px;
    border-top: unset;
    border-bottom: 8px solid rgba(255, 255, 255, 0.3);
  }
}

nav ul li a:before {
  display: block;
  content: "❖";
  font-size: 1.4rem;
}

article {
  display: block;
  width: calc(100% - 2rem);
  position: absolute;
  bottom: 68px;
  margin: 1rem 1rem 0 1rem;
  padding: 1.5rem;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(15px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  max-width: 860px;
}

@media (min-width: 768px){
  article{
    width: calc(60% - 2rem);
    left: 40%;
    border: 0px solid rgba(255, 255, 255, 0.3);
  }
}

article h1 {
  line-height: 1.2;
  font-size: clamp(1.75rem, -0.6058rem + 6.7308vw, 4rem);
  margin-bottom: calc( clamp(1.75rem, -0.6058rem + 6.7308vw, 2.625rem) / 2 );
  text-transform: uppercase;
  font-style: italic;
  font-weight: lighter;
  letter-spacing: 2px;
}

article h2 {
  line-height: 1.2;
  font-size: clamp(1.125rem, -1.2308rem + 6.7308vw, 1.6rem);
  margin-bottom: calc( clamp(1.75rem, -0.6058rem + 6.7308vw, 2.625rem) / 2 );
  margin-top: clamp(1.75rem, -0.6058rem + 6.7308vw, 2.625rem);
}

article p {
  line-height: 1.6;
  font-size: clamp(0.875rem, 0.5385rem + 0.9615vw, 1rem);
}

article p {
  margin-bottom: calc( clamp(1.75rem, -0.6058rem + 6.7308vw, 2.625rem) / 2 );
}

article p:last-child {
  margin-bottom: 0;
}
